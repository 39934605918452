import { atom, selector, selectorFamily, atomFamily } from "recoil";
import axios from "axios";
import api from "../api.config";

const PORTAL_API = 'staff_portal_api'

export const calendarEventsByMonthAtom = atomFamily({
  key: 'calendarEventsByMonth',
  default: selectorFamily({
    id: 'calendarEventsByMonthSelector',
    get: ({ calendars, month, year }) => async () => {
      return await getCalendarEventsForMonth(calendars, month, year)
    }
  })
})

export const getCalendarEventsForMonth = async (calendars, month, year) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/getCalendarsEventsByMonth`, {
      calendars: calendars,
      month: month,
      year: year
    })
    .then((res) => { return res.data });
}

export const myCalendarSettingsForCalendarAtom = atom({
  key: "myCalendarSettingsForCalendar",
  default: selector({
    key: "myCalendarSettingsForCalendarSelector",
    get: async () => {
      return await getMyCalendarSettingsForCalendar()
    },
  }),
})

export const getMyCalendarSettingsForCalendar = async () => {
  return await axios
    .get(`${api.path}/staff_portal_api/v1/getMyCalendarSettingsForCalendar`)
    .then((res) => {
      return res.data;
    });
}